import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import BlockContentSimple from '@components/BlockContentSimple';

import { moduleSettings, hasImage } from '@utils';

const blogSettings = moduleSettings('blog');

const Post = ({ post, className }) => {
  const imageExists = hasImage(post.image);

  const settings = useStaticQuery(graphql`
    query {
      settings: sanityBlogSettings {
        columns
      }
    }
  `);

  const columns =
    settings && settings.settings && settings.settings.columns
      ? settings.settings.columns
      : 3;

  return (
    <Link
      className={`openModal flex flex-col rounded-lg shadow-lg overflow-hidden bg-gray-100 text-left no-underline transition duration-300 linear transform hover:-translate-y-1 hover:shadow-2xl ${className}`}
      to={`/${blogSettings.baseName}/${
        post.pageInfo && post.pageInfo.slug && post.pageInfo.slug.current
      }`}
    >
      <div className="flex-shrink-0">
        {imageExists && (
          <Img
            className={`w-full ${
              columns < 2 ? 'h-75' : 'h-60'
            } object-cover object-center`}
            fluid={post.image.asset.fluid}
            alt={post.pageInfo.name}
          />
        )}
      </div>
      <div className="flex-1 p-6 flex flex-col justify-between">
        <div className="flex-1">
          <h3 className="mt-2 mb-3 text-xl leading-7 font-primary">
            {post.pageInfo.name}
          </h3>
          {post._rawExcerpt && post._rawExcerpt.length > 0 ? (
            <BlockContentSimple
              className="text-base"
              blocks={post._rawExcerpt || []}
            />
          ) : (
            <p className="text-base">{post.excerpt}</p>
          )}
        </div>
        <div className="mt-6 flex items-center space-x-4">
          {post.author &&
            post.author.image &&
            post.author.image.asset &&
            post.author.image.asset.fluid && (
              <div className="flex-shrink-0">
                <Img
                  className="h-10 w-10 rounded-full mb-0"
                  fluid={post.author.image.asset.fluid}
                  alt=""
                />
              </div>
            )}

          <div className="">
            {post.author &&
              post.author.pageInfo &&
              post.author.pageInfo.name && (
                <p className="text-sm leading-5 font-medium text-gray-900 mb-0">
                  {post.author.pageInfo.name}
                </p>
              )}
            <div className="flex text-sm leading-5 text-gray-500">
              {post.publishedAt && (
                <div>
                  <time dateTime={post.date}>{post.dateReadable}</time>
                </div>
              )}
              {post.readTime && post.publishedAt && (
                <span className="mx-1">&middot;</span>
              )}
              {post.readTime && <span>{post.readTime} min read</span>}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Post;
